import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { fetchLpSymbolMapUpdate, onlyFetchGlobalSymbolsWithAliases } from '../../redux/actions/system-settings-actions'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { MaxLength } from '../../utils/schema-utils'

interface IGlobalSymbolsWithAliases {
  Name: string
  Aliases: string[]
}
const schema = (type: string) => {
  const arrType = ['ADSSo', 'IG', 'IGDMA', 'StoneX', 'InteractiveBrokers']
  const validType = arrType.includes(type)

  return {
    Symbol: yup
      .object({
        value: yup
          .string()
          .test('Is Unknown?', 'ERROR: Symbol Unknown!', value => value !== 'Unknown')
          .required(),
        label: yup
          .string()
          .test('Is Unknown?', 'ERROR: Symbol Unknown!', value => value !== 'Unknown')
          .required(),
      })
      .required(),
    LpSymbol: yup
      .string()
      .matches(/^[^=]+$/gi)
      .required(),
    Exchange: type === 'InteractiveBrokers' ? yup.string().required() : yup.string().nullable(),
    DealCurrency: validType ? yup.string().required() : yup.string().nullable(),
    ContractMultiplier: yup
      .string()
      .matches(/^[0-9.]+$/gi)
      .transform(MaxLength)
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
    VolumeStep: yup
      .string()
      .matches(/|^[0-9.]+$/gi)
      .transform(MaxLength)
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0 || !value)
      .nullable()
      .notRequired(),
    Type: type === 'InteractiveBrokers' || type === 'EdgeWater' ? yup.string().required() : yup.string().nullable(),
    SettlType: type === 'EdgeWater' ? yup.string().required() : yup.string().nullable(),
  }
}

const LpSymbolMapRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const schemaObj = schema(params.LpType)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      ContractSize: String(item.ContractSize) ?? '',
      Symbol: { label: item.Symbol, value: item.Symbol },
    },
    schemaObj,
  )

  const [globalSymbols, setGlobalSymbols] = useState<IGlobalSymbolsWithAliases[]>([])
  const handleSave = () => {
    if (!isValid()) {
      return
    }

    dispatch(
      fetchLpSymbolMapUpdate({
        action: type,
        params,
        body: { ...inputState, Symbol: inputState.Symbol.value, Name: inputState.Name },
      }),
    )

    dispatch(hideRightBar())
  }

  useEffect(() => {
    onlyFetchGlobalSymbolsWithAliases(params).then((symbols: IGlobalSymbolsWithAliases[]) => {
      setGlobalSymbols(symbols)
      if (type === 'add') {
        setInputState({ ...item, Symbol: { label: symbols, value: symbols } && { label: symbols[0].Name, value: symbols[0].Name }, VolumeStep: null })
      }
      const newSymbils = symbols.map((item: any) => item.Name.trim())
      if (!newSymbils?.includes(item.Symbol?.trim()) && type === 'modify') {
        setInputState({
          ...item,
          Symbol: { label: 'Unknown', value: 'Unknown' },
        })
      }
    })
  }, [])

  useEffect(() => {
    setInputState((prev: any) => {
      return {
        ...prev,
        VolumeStep: inputState.VolumeStep === '' ? null : inputState.VolumeStep,
      }
    })
  }, [inputState.VolumeStep])

  const newGlobalSymbols = useMemo(
    () =>
      globalSymbols.map((item: IGlobalSymbolsWithAliases) => {
        return {
          label: item.Name,
          value: item.Name,
          aliases: item.Aliases,
        }
      }),
    [globalSymbols],
  )

  const inputs = buildControlsExtTwoPerLine(
    [
      sselectInput('Symbol', newGlobalSymbols).setTooltip(true, 'Search by name and alias...'),
      textInput('LpSymbol', type === 'modify'),
      textInput('ContractMultiplier'),
      textInput('VolumeStep'),
      textInput('DealCurrency').skipWhen(
        params.LpType !== 'ADSSo' && params.LpType !== 'IG' && params.LpType !== 'IGDMA' && params.LpType !== 'StoneX' && params.LpType !== 'InteractiveBrokers',
      ),
      textInput('Type').skipWhen(params.LpType !== 'AdvancedMarkets' && params.LpType !== 'InteractiveBrokers' && params.LpType !== 'EdgeWater'),
      textInput('Exchange').skipWhen(params.LpType !== 'InteractiveBrokers'),
      textInput('SettlType').skipWhen(params.LpType !== 'EdgeWater'),
    ],
    inputState,
    setInputState,
    'lp-symbol-map',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.${type}`} />,
          item: inputs,
        }}
        isHidden={false}
        ref={ref}
        render={ref.current}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}
export default LpSymbolMapRightbar
